import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useMainStore } from '../stores/mainStore';
import {
  Typography,
  Grid,
  Avatar,
  Badge,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  Switch,
  Paper,
  MenuItem,
  Select,
  FormControl,
  SelectChangeEvent,
  Card,
  CardContent,
  Button,
  SvgIcon,
  IconButton,
  Box,
  Tab,
  Tabs,
} from '@mui/material';
import { getImageSrc, langCodeMap } from '../utils';
import { useShallow } from 'zustand/shallow';
import { styled } from '@mui/material/styles';
import { FeedItem } from './FeedItem';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { LangLink } from './LangLink';
import { Trans } from './Trans';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import ArticleIcon from '@mui/icons-material/Article';
import SecurityIcon from '@mui/icons-material/Security';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

const BackgroundImage = styled('div')<{ image: string }>(({ image }) => ({
  height: '200px',
  backgroundImage: `url(${getImageSrc(image)})`,
  backgroundSize: 'cover',
  position: 'relative',
}));

const ProfileSection = styled('div')({
  position: 'relative',
  marginTop: '-75px',
  textAlign: 'center',
});

const AvatarWrapper = styled('div')({
  position: 'relative',
  width: 150,
  height: 150,
  margin: '0 auto',
});

const StyledAvatar = styled(Avatar)({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const StyledCircularProgress = styled(CircularProgress)({
  position: 'absolute',
  top: -5,
  left: -5,
  zIndex: 0,
  '& .MuiCircularProgress-circle': {
    strokeLinecap: 'round',
  },
});

export const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'transparent',
    background: 'linear-gradient(to top, #fde047, #f97316)',
    color: 'white',
    fontSize: '1.2rem',
    height: '80px',
    minWidth: '80px',
    padding: '0',
    transform: 'translate(50%, 50%)',
    clipPath:
      'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)', // Star shape
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const tierMap = ['Free', 'Basic Pass', 'VIP Pass', 'God Mode'];

// Custom Discord icon
const DiscordIcon = (props: any) => (
  <SvgIcon {...props}>
    <path d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09-.01-.02-.04-.03-.07-.03-1.5.26-2.93.71-4.27 1.33-.01 0-.02.01-.03.02-2.72 4.07-3.47 8.03-3.1 11.95 0 .02.01.04.03.05 1.8 1.32 3.53 2.12 5.24 2.65.03.01.06 0 .07-.02.4-.55.76-1.13 1.07-1.74.02-.04 0-.08-.04-.09-.57-.22-1.11-.48-1.64-.78-.04-.02-.04-.08-.01-.11.11-.08.22-.17.33-.25.02-.02.05-.02.07-.01 3.44 1.57 7.15 1.57 10.55 0 .02-.01.05-.01.07.01.11.09.22.17.33.26.04.03.04.09-.01.11-.52.31-1.07.56-1.64.78-.04.01-.05.06-.04.09.32.61.68 1.19 1.07 1.74.03.02.06.03.09.02 1.72-.53 3.45-1.33 5.25-2.65.02-.01.03-.03.03-.05.44-4.53-.73-8.46-3.1-11.95-.01-.01-.02-.02-.04-.02zM8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12 0 1.17-.84 2.12-1.89 2.12zm6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12 0 1.17-.83 2.12-1.89 2.12z" />
  </SvgIcon>
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

// Add this styled component near the top with other styled components
const TierCard = styled(Card)(() => ({
  maxWidth: 600,
  margin: '0 auto',
  background: 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(10px)',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
  },
}));

export const Profile: React.FC = () => {
  const { id, lang } = useParams<{ id: string; lang: string }>();
  const getCharacterPosts = useMainStore((state) => state.getCharacterPosts);
  const posts = useMainStore(
    useShallow((state) => state.characterPosts[id || ''] || [])
  );
  const tier = useMainStore((state) => state.tier);
  const loggedIn = useMainStore((state) => state.loggedIn);
  const activeCharacter = useMainStore((state) => state.activeCharacter);
  const setActiveCharacter = useMainStore((state) => state.setActiveCharacter);
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const followCharacter = useMainStore((state) => state.followCharacter);
  const restartProgress = useMainStore((state) => state.restartProgress);
  const restartCharacter = useMainStore((state) => state.restartCharacter);
  const resendVerification = useMainStore((state) => state.resendVerification);
  const [openModal, setOpenModal] = useState(false);
  const [userId, setUserId] = useState<string>('');
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate();
  const setTag = useMainStore((state) => state.setTag);
  const tags = useMainStore((state) => state.tags);
  const [futasSelected, setFutasSelected] = useState(
    tags?.includes('futas') || false
  );
  const [subscribeStarSyncing, setSubscribeStarSyncing] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const setLoginModalOpen = useMainStore((state) => state.setLoginModalOpen);

  const level = activeCharacter?.score
    ? Math.floor(activeCharacter.score / 30) + 1
    : 1;
  let progress = activeCharacter?.score
    ? ((activeCharacter.score % 30) / 30) * 100
    : 0;
  if (progress === 0) {
    progress = 100;
  }

  useEffect(() => {
    if (tags?.includes('futas')) {
      setFutasSelected(true);
    } else {
      setFutasSelected(false);
    }
  }, [tags]);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        await setActiveCharacter(id);
        await getCharacterPosts(id);
      }
    };
    fetchData();
  }, [id]);

  const loadPosts = async () => {
    if (id && !isLoading) {
      setIsLoading(true);
      await getCharacterPosts(id);
      setIsLoading(false);
    }
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        loadPosts();
      }
    }
  };

  const handleFollow = async () => {
    if (id) {
      await followCharacter(id);
    }
  };

  const handleFutasChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFutasSelected(event.target.checked);
    setTag('futas', event.target.checked);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (event) {
      console.log('changed');
    }
    setTabValue(newValue);
  };

  if (!activeCharacter) {
    return (
      <Typography>
        <Trans>Loading...</Trans>
      </Typography>
    );
  }

  return (
    <div className="app-container1 overflow-y-auto">
      <div className="flex flex-col">
        {id === 'me' && (
          <>
            <Paper
              elevation={0}
              sx={{
                p: 2,
                mb: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                borderRadius: 0,
              }}
            >
              {/* Enhanced Navigation Menu with Button styling and icons */}
              <div className="flex justify-start mb-4 gap-3">
                <Button
                  component={Link}
                  to="/terms"
                  variant="outlined"
                  size="small"
                  startIcon={<ArticleIcon />}
                  sx={{
                    borderRadius: '20px',
                    color: 'text.secondary',
                    borderColor: 'rgba(0,0,0,0.12)',
                    '&:hover': {
                      backgroundColor: 'rgba(255,192,203,0.1)',
                      borderColor: 'pink.main',
                    },
                  }}
                >
                  <Trans>Terms</Trans>
                </Button>

                <Button
                  component={Link}
                  to="/privacy"
                  variant="outlined"
                  size="small"
                  startIcon={<SecurityIcon />}
                  sx={{
                    borderRadius: '20px',
                    color: 'text.secondary',
                    borderColor: 'rgba(0,0,0,0.12)',
                    '&:hover': {
                      backgroundColor: 'rgba(255,192,203,0.1)',
                      borderColor: 'pink.main',
                    },
                  }}
                >
                  <Trans>Privacy</Trans>
                </Button>

                <IconButton
                  component="a"
                  href="https://discord.gg/gwZKNENRdx"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    borderRadius: '20px',
                    color: '#5865F2', // Discord brand color
                    '&:hover': {
                      backgroundColor: 'rgba(88, 101, 242, 0.1)',
                      borderColor: '#5865F2',
                    },
                  }}
                >
                  <DiscordIcon />
                </IconButton>
              </div>

              <div className="flex items-start gap-4">
                <VisibilityIcon sx={{ color: 'text.secondary', mt: 0 }} />
                <div className="flex gap-8 justify-between w-full">
                  <div className="flex flex-col items-start">
                    <Typography variant="body1" sx={{ mb: -0.5 }}>
                      <Trans>Futas</Trans>
                    </Typography>
                    <Switch
                      checked={futasSelected}
                      onChange={handleFutasChange}
                      name="futas"
                      color="primary"
                    />
                  </div>
                </div>
                <FormControl
                  variant="outlined"
                  sx={{ minWidth: 120, marginBottom: 2 }}
                >
                  <Select
                    value={lang}
                    onChange={(e: SelectChangeEvent<string>) =>
                      (window.location.href = window.location.pathname.replace(
                        lang || '',
                        e.target.value
                      ))
                    }
                    displayEmpty
                    inputProps={{ 'aria-label': 'Select Language' }}
                  >
                    {Object.entries(langCodeMap).map(([code, name]) => (
                      <MenuItem key={code} value={code}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Paper>
          </>
        )}
        <BackgroundImage image={activeCharacter.backgroundImage || ''}>
          <div className="flex justify-between p-4">
            <button onClick={() => navigate(-1)} className="text-white">
              <i className="mdi mdi-arrow-left mdi-24px"></i>
            </button>
            <button className="text-white">
              <i className="mdi mdi-dots-vertical mdi-24px"></i>
            </button>
          </div>
        </BackgroundImage>

        <ProfileSection>
          <StyledBadge
            badgeContent={level}
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <AvatarWrapper>
              <StyledCircularProgress
                variant="determinate"
                value={100} // You can make this dynamic based on your needs
                size={160}
                thickness={5}
                sx={{
                  color: '#fae6c0', // Or use your gradient colors
                }}
              />
              <StyledCircularProgress
                variant="determinate"
                value={progress}
                size={160}
                thickness={5}
                sx={{
                  color: '#ffa500',
                  transform: 'rotate(45deg)',
                }}
              />
              <StyledAvatar
                src={getImageSrc(activeCharacter.images)}
                alt={activeCharacter.name}
              />
            </AvatarWrapper>
          </StyledBadge>
          <div className="mt-4 flex items-start justify-center">
            <Typography variant="h5" component="div">
              {activeCharacter.name}
            </Typography>
          </div>
          <Typography
            variant="body1"
            color="text.secondary"
            className="pt-4 text-left px-4"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: isExpanded ? 'none' : 7,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {(activeCharacter.descriptionTrans || activeCharacter.description)
              ?.split('\n')
              .map((line, index, array) => (
                <React.Fragment key={index}>
                  {line}
                  {index < array.length - 1 && <br />}
                </React.Fragment>
              ))}
          </Typography>
          {activeCharacter.description &&
            (
              activeCharacter.descriptionTrans || activeCharacter.description
            ).split('').length > 100 && (
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className="text-pink-500 hover:text-pink-600 mt-2 block px-4 italic"
              >
                {isExpanded ? (
                  <Trans>Show Less</Trans>
                ) : (
                  <Trans>Show More</Trans>
                )}
              </button>
            )}
          {id === 'me' && (
            <div className="flex justify-center gap-4 mt-4">
              <button
                onClick={restartProgress}
                className="px-6 py-2 border border-pink-500 text-pink-500 rounded-full"
              >
                <Trans>Restart progress</Trans>
              </button>
              <button
                onClick={() => {
                  const token = localStorage.getItem('token');
                  const user = token && JSON.parse(atob(token.split('.')[1]));
                  setUserId(user?.user || 'No ID found');
                  setOpenModal(true);
                }}
                className="px-6 py-2 border border-green-500 text-green-500 rounded-full"
              >
                <Trans>Show ID</Trans>
              </button>
            </div>
          )}
          {id === 'me' ? (
            <>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 4 }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  centered
                  variant="fullWidth"
                >
                  <Tab
                    icon={<AccountCircleIcon />}
                    label={<Trans>Account</Trans>}
                  />
                  <Tab
                    icon={<WorkspacePremiumIcon />}
                    label={<Trans>Subscription Tiers</Trans>}
                  />
                </Tabs>
              </Box>

              <TabPanel value={tabValue} index={0}>
                {loggedIn ? (
                  <Card sx={{ maxWidth: 345, margin: '12px auto' }}>
                    <CardContent>
                      <div className="mt-4">
                        <Typography variant="subtitle1" className="text-left">
                          <Trans>Email</Trans>
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          className="text-left"
                        >
                          {activeCharacter.mainEmail || (
                            <LangLink to="/signup">
                              <span className="font-bold">
                                <Trans>Add email</Trans>
                              </span>
                            </LangLink>
                          )}
                          {activeCharacter.mainEmailVerified ? (
                            <span className="text-green-500 ml-2">
                              <Trans>verified</Trans>
                            </span>
                          ) : activeCharacter.mainEmail &&
                            !activeCharacter.mainEmailVerified ? (
                            <>
                              <span className="text-red-500 ml-2">
                                <Trans>unverified</Trans>
                              </span>
                              <div>
                                <Button
                                  sx={{ margin: '4px 0' }}
                                  variant="outlined"
                                  size="small"
                                  color="primary"
                                  disabled={verificationLoading}
                                  onClick={async () => {
                                    setVerificationLoading(true);
                                    try {
                                      await resendVerification();
                                    } finally {
                                      setVerificationLoading(false);
                                    }
                                  }}
                                >
                                  <Trans>Resend verification email</Trans>
                                </Button>
                              </div>
                            </>
                          ) : null}
                        </Typography>
                      </div>
                      <Typography
                        variant="h6"
                        className="text-left"
                        sx={{ mt: 2 }}
                      >
                        <Trans>Connected accounts</Trans>
                      </Typography>
                      <div className="mt-2">
                        <Typography variant="subtitle1" className="text-left">
                          <Trans>Patreon</Trans>
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          className="text-left"
                        >
                          {activeCharacter.email || (
                            <a
                              href={`https://www.patreon.com/oauth2/authorize?response_type=code&client_id=1LVj7EWwjKSffaqkuJlF3bqqF6Xx9L9YWv-ZrXdJdkqwanrhcikf5DFIxjmHV9JF&redirect_uri=https://lewdspace.com/api/patreon&scope=identity%20identity%5Bemail%5D&state=${
                                localStorage.getItem('token') || 'null'
                              }`}
                              className="text-blue-500 hover:underline"
                            >
                              <Trans>Connect your account</Trans>
                            </a>
                          )}
                        </Typography>
                        {'patreonTier' in activeCharacter &&
                          activeCharacter.patreonTier !== null && (
                            <div className="flex flex-col items-start mt-2">
                              <Typography variant="body2" className="text-left">
                                <Trans>Tier:</Trans>{' '}
                                {activeCharacter.patreonTier !== undefined &&
                                activeCharacter.patreonTier !== null ? (
                                  tierMap[activeCharacter.patreonTier]
                                ) : (
                                  <Trans>No tier</Trans>
                                )}
                              </Typography>
                              <Typography variant="body2" className="text-left">
                                <Trans>Expiration:</Trans>{' '}
                                <Trans>
                                  {new Date(
                                    activeCharacter.patreonExp || 0
                                  ).toLocaleDateString()}
                                </Trans>
                              </Typography>
                              <div className="flex justify-start w-full">
                                <Button
                                  variant="outlined"
                                  color="error"
                                  sx={{ my: '4px' }}
                                  href="https://www.patreon.com/settings/memberships/LewdSpace"
                                >
                                  <Trans>Unsubscribe</Trans>
                                </Button>
                              </div>
                            </div>
                          )}
                      </div>
                      <div className="mt-4">
                        <Typography variant="subtitle1" className="text-left">
                          <Trans>SubscribeStar</Trans>
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          className="text-left"
                        >
                          {activeCharacter.subscribeStarEmail || (
                            <a
                              href={`https://www.subscribestar.com/oauth2/authorize?client_id=KComRjJMGCBtyEGpVSpbU4PvQfGdLOfFNCFsBiirOgk&redirect_uri=https://lewdspace.com/api/subscribestar?state=${
                                localStorage.getItem('token') || 'null'
                              }&response_type=code&scope=subscriber.read+subscriber.payments.read+user.read+user.email.read`}
                              className="text-blue-500 hover:underline"
                            >
                              <Trans>Connect your account</Trans>
                            </a>
                          )}
                        </Typography>
                        {'subscribeStarTier' in activeCharacter &&
                          activeCharacter.subscribeStarTier !== null && (
                            <div className="flex flex-col items-start mt-2">
                              <Typography variant="body2" className="text-left">
                                <Trans>Tier:</Trans>{' '}
                                {activeCharacter.subscribeStarTier !==
                                  undefined &&
                                activeCharacter.subscribeStarTier !== null ? (
                                  tierMap[activeCharacter.subscribeStarTier]
                                ) : (
                                  <Trans>No tier</Trans>
                                )}
                              </Typography>
                              {activeCharacter.subscribeStarTier ? (
                                <>
                                  <Typography
                                    variant="body2"
                                    className="text-left"
                                  >
                                    <Trans>Expiration:</Trans>{' '}
                                    <Trans>
                                      {new Date(
                                        activeCharacter.subscribeStarExp || 0
                                      ).toLocaleDateString()}
                                    </Trans>
                                  </Typography>
                                </>
                              ) : null}
                              <div className="flex justify-start w-full">
                                <Button
                                  variant="outlined"
                                  color="error"
                                  sx={{ my: '4px' }}
                                  href="https://www.subscribestar.com/lewdspace/unsubscribe"
                                >
                                  <Trans>Unsubscribe</Trans>
                                </Button>
                              </div>
                            </div>
                          )}
                        {activeCharacter.subscribeStarEmail ? (
                          <div className="flex justify-start w-full">
                            <Button
                              disabled={subscribeStarSyncing}
                              sx={{ alignItems: 'flex-start' }}
                              variant="outlined"
                              color="success"
                              className="mt-2"
                              onClick={() => {
                                setSubscribeStarSyncing(true);
                                window.location.href = `https://www.subscribestar.com/oauth2/authorize?client_id=KComRjJMGCBtyEGpVSpbU4PvQfGdLOfFNCFsBiirOgk&redirect_uri=https%3A%2F%2Flewdspace.com%2Fapi%2Fsubscribestar%3Fredirect%3Dprofile%26state%3D${
                                  localStorage.getItem('token') || 'null'
                                }&response_type=code&scope=subscriber.read+subscriber.payments.read+user.read+user.email.read`;
                              }}
                            >
                              <Trans>Synchronize</Trans>
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    </CardContent>
                  </Card>
                ) : (
                  <Card sx={{ maxWidth: 345, margin: '12px auto' }}>
                    <CardContent>
                      <Typography variant="h6" align="center" gutterBottom>
                        <Trans>Login or signup to view account info</Trans>
                      </Typography>
                      <Button
                        onClick={() => setLoginModalOpen(true)}
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2 }}
                      >
                        <Trans>Login / Signup</Trans>
                      </Button>
                    </CardContent>
                  </Card>
                )}
              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                <div className="flex flex-col gap-6">
                  {[
                    {
                      img: '/public/tier1.jpg',
                      title: 'BASIC PASS',
                      emoji: '💦',
                      price: '5',
                      features: [
                        'Unlock exclusive characters',
                        '5 custom messages per day (write your own text in chat)',
                      ],
                    },
                    {
                      img: '/public/tier2.jpg',
                      title: 'VIP PASS',
                      emoji: '👑',
                      price: '8',
                      features: [
                        'Unlock exclusive characters',
                        '25 custom messages per day (write your own text in chat)',
                        '5 poses to choose from every turn (during sex scene)',
                      ],
                    },
                    {
                      img: '/public/tier3.jpg',
                      title: 'GOD MODE',
                      emoji: '⚡',
                      price: '14',
                      features: [
                        'Unlock exclusive characters',
                        'Unlimited custom messages (write your own text in chat)',
                        'Master of seduction💕 - start a sex scene whenever you want',
                        'Stamina king💦 - you decide when to cum',
                        'The Undeniable - send messages even to characters who blocked you',
                      ],
                    },
                  ].map((tier, index) => (
                    <TierCard key={index} elevation={3}>
                      <CardContent sx={{ p: 0 }}>
                        <div className="relative">
                          <img
                            src={tier.img}
                            alt={tier.title}
                            className="w-full aspect-[16/9] object-cover"
                          />
                          <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
                          <div className="absolute bottom-4 left-4 text-white">
                            <Typography
                              variant="h4"
                              sx={{
                                fontWeight: 'bold',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                              }}
                            >
                              {tier.title}{' '}
                              <span style={{ fontSize: '1.5em' }}>
                                {tier.emoji}
                              </span>
                            </Typography>
                            <Typography
                              variant="h5"
                              sx={{
                                color: 'rgba(255, 255, 255, 0.9)',
                                display: 'flex',
                                alignItems: 'baseline',
                                gap: 1,
                              }}
                            >
                              <span style={{ fontSize: '1.5em' }}>
                                ${tier.price}
                              </span>
                              <span style={{ fontSize: '0.8em', opacity: 0.8 }}>
                                /month
                              </span>
                            </Typography>
                          </div>
                        </div>
                        <div className="p-6">
                          <div className="flex flex-col gap-3 items-start w-full">
                            {tier.features.map((feature, idx) => (
                              <div
                                key={idx}
                                className="flex items-start gap-3 w-full"
                              >
                                <div className="w-1.5 h-1.5 rounded-full bg-pink-500 flex-shrink-0 mt-2" />
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: 'text.primary',
                                    lineHeight: 1.4,
                                    textAlign: 'left',
                                    flex: 1,
                                  }}
                                >
                                  {feature}
                                </Typography>
                              </div>
                            ))}
                          </div>
                        </div>
                      </CardContent>
                    </TierCard>
                  ))}
                </div>
              </TabPanel>
            </>
          ) : null}
          {id !== 'me' && (
            <div className="flex justify-center gap-4 mt-4">
              <button
                onClick={handleFollow}
                className={`px-6 py-2 rounded-full ${
                  activeCharacter.active
                    ? 'border border-pink-500 text-pink-500'
                    : 'bg-pink-500 text-white'
                }`}
              >
                {activeCharacter.active ? (
                  <Trans>Unfollow</Trans>
                ) : (
                  <Trans>Follow</Trans>
                )}
              </button>
              <LangLink
                to={`/app/chat/${id}`}
                className="px-6 py-2 border border-pink-500 text-pink-500 rounded-full"
              >
                <Trans>Send message</Trans>
              </LangLink>
              {tier ? (
                <button
                  onClick={() => restartCharacter(id || '')}
                  className="px-6 py-2 border border-pink-500 text-pink-500 rounded-full"
                >
                  <SpeakerNotesOffIcon />
                </button>
              ) : null}
            </div>
          )}
        </ProfileSection>

        <div
          ref={containerRef}
          onScroll={handleScroll}
          className="flex-1 overflow-auto pt-6 mb-12"
        >
          <div className="px-4 py-8">
            <Grid container spacing={3}>
              {posts
                .filter((post) => post._id)
                .map((post) => (
                  <FeedItem key={post._id} item={post} me={id === 'me'} />
                ))}
            </Grid>
            {isLoading && (
              <Typography align="center" className="mt-4">
                <Trans>Loading more posts...</Trans>
              </Typography>
            )}
          </div>
        </div>
      </div>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>
          <Trans>User ID</Trans>
          <IconButton
            aria-label="close"
            onClick={() => setOpenModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="flex items-start gap-2">
            <Typography>{userId}</Typography>
            <Tooltip
              title="ID copied!"
              arrow
              placement="top"
              open={showTooltip}
              onClose={() => setShowTooltip(false)}
            >
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(userId);
                  setShowTooltip(true);
                  setTimeout(() => setShowTooltip(false), 1500);
                }}
                size="small"
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
