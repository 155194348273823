import { useMainStore } from '../stores/mainStore';
import LogoutIcon from '@mui/icons-material/Logout';
import { LangLink } from './LangLink';
import { Trans } from './Trans';
import { useState } from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Button,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { AccountCircle } from '@mui/icons-material';

export const AppHeader = () => {
  const loggedIn = useMainStore((state) => state.loggedIn);
  const logout = useMainStore((state) => state.logout);
  const emailLogin = useMainStore((state) => state.emailLogin);
  const isLoginModalOpen = useMainStore((state) => state.isLoginModalOpen);
  const setLoginModalOpen = useMainStore((state) => state.setLoginModalOpen);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const sendPasswordReset = useMainStore((state) => state.sendPasswordReset);

  const handleLoginClick = () => {
    setLoginModalOpen(true);
  };

  const handleCloseModal = () => {
    setLoginModalOpen(false);
    setEmail('');
    setPassword('');
    setError('');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      await emailLogin(email, password);
    } catch (err: any) {
      setError('Login failed');
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      await sendPasswordReset(email);
      setError('');
      setEmail('');
    } catch (err: any) {
      setError('Failed to send reset email');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex border-b pb-1 border-gray-100 justify-between">
      <div className="">
        <LangLink to="/">
          <img
            src="/public/logo2.png"
            style={{ height: '40px', width: '126px' }}
          />
        </LangLink>
      </div>
      <div className="flex space-x-4 items-center">
        {!loggedIn ? (
          <button
            onClick={handleLoginClick}
            className="flex items-center px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-all duration-300"
          >
            <AccountCircle sx={{ fontSize: 20, marginRight: 1 }} />
            <span className="text-sm">
              <Trans>Login</Trans>
            </span>
          </button>
        ) : (
          <button
            onClick={logout}
            className="flex items-center px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-all duration-300"
          >
            <LogoutIcon sx={{ fontSize: 20, marginRight: 1 }} />
            <span className="text-sm">
              <Trans>Log Out</Trans>
            </span>
          </button>
        )}
      </div>
      <Dialog
        open={isLoginModalOpen}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            maxWidth: 'calc(394px - 56px)',
            width: '100%',
          },
        }}
      >
        <div className="flex justify-end p-2">
          <IconButton onClick={handleCloseModal} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent sx={{ paddingTop: '0px' }}>
          <div className="flex flex-col space-y-4">
            <h2 className="text-center text-xl font-semibold">
              <Trans>Login Options</Trans>
            </h2>
            <form
              onSubmit={isForgotPassword ? handleForgotPassword : handleSubmit}
              className="w-full"
            >
              <div className="space-y-3 mb-4">
                <TextField
                  fullWidth
                  type="email"
                  label={<Trans>Email</Trans>}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  size="small"
                  error={!!error}
                />
                {!isForgotPassword && (
                  <TextField
                    fullWidth
                    type="password"
                    label={<Trans>Password</Trans>}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    size="small"
                    error={!!error}
                  />
                )}
                {error && (
                  <div className="text-red-500 text-sm text-center">
                    <Trans>{error}</Trans>
                  </div>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loading}
                  sx={{ height: '40px' }}
                >
                  {loading ? (
                    <Trans>Processing...</Trans>
                  ) : isForgotPassword ? (
                    <Trans>Send Reset Link</Trans>
                  ) : (
                    <Trans>Login</Trans>
                  )}
                </Button>
                <div className="text-center">
                  <Button
                    onClick={() => {
                      setIsForgotPassword(!isForgotPassword);
                      setError('');
                    }}
                    color="primary"
                  >
                    <Trans>
                      {isForgotPassword ? 'Back to Login' : 'Forgot Password?'}
                    </Trans>
                  </Button>
                </div>
              </div>
            </form>

            <div className="flex items-center my-4">
              <div className="flex-1 border-t border-gray-300"></div>
              <span className="px-4 text-gray-500">
                <Trans>or</Trans>
              </span>
              <div className="flex-1 border-t border-gray-300"></div>
            </div>
            <a
              target="_top"
              href={`https://www.patreon.com/oauth2/authorize?response_type=code&client_id=1LVj7EWwjKSffaqkuJlF3bqqF6Xx9L9YWv-ZrXdJdkqwanrhcikf5DFIxjmHV9JF&redirect_uri=https://lewdspace.com/api/patreon&scope=identity%20identity%5Bemail%5D&state=${
                localStorage.getItem('token') || 'null'
              }`}
              className="w-full"
            >
              <img
                src="/public/patreonLogin.jpg"
                alt="Login with Patreon"
                className="w-full h-auto"
                style={{ marginBottom: '14px' }}
              />
            </a>
            <a
              target="_top"
              href={`https://www.subscribestar.com/oauth2/authorize?client_id=KComRjJMGCBtyEGpVSpbU4PvQfGdLOfFNCFsBiirOgk&redirect_uri=https://lewdspace.com/api/subscribestar?state=${
                localStorage.getItem('token') || 'null'
              }&response_type=code&scope=subscriber.read+subscriber.payments.read+user.read+user.email.read`}
              className="w-full"
              style={{ padding: '0 12px' }}
            >
              <img
                src="/public/subscribeStarLogo.png"
                alt="Login with SubscribeStar"
                className="w-full h-auto"
              />
            </a>
            <div className="flex justify-center mt-4">
              <LangLink to="/signup">
                <Trans>Don't have an account?</Trans>{' '}
                <span className="font-bold">
                  <Trans>Sign up</Trans>
                </span>
              </LangLink>
            </div>
            {/* <div className="flex justify-center mt-2">
              <a href="/help" className="text-blue-500 hover:underline">
                <Trans>Need help?</Trans>
              </a>
            </div> */}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
